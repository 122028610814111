<template>
  <div class="col-auto px-1" @click="focus">
    <div class="row mx-0 align-items-center position-relative">
      <div class="col-12 p-0">
        <CurrencyFieldSymbol :isActive="isActive" />
        <input
          class="form-control bold"
          :class="{ isActive: isActive}"
          type="number"
          min="0.00"
          step="0.01"
          :id="id"
          :placeholder="placeholder"
          :autocomplete="autocomplete"
          v-model="value"
          @blur="notFocus"
        >
      </div>
    </div>
  </div>
</template>

<script>
import CurrencyFieldSymbol from './CurrencyFieldSymbol.vue'

export default {
  name: 'CurrencyField',
  props: ['id', 'placeholder', 'modelValue', 'autocomplete', 'currencySymbol'],
  emits: ['update:modelValue', 'checkAmount', 'customFocus'],
  components: {
    CurrencyFieldSymbol
  },
  data () {
    return {
      defaultCurrency: process.env.VUE_APP_CURRENCY_SYMBOL,
      isFocus: false
    }
  },
  watch: {
    value (val) {
      if (val < 0) {
        this.value = 0
      }
    }
  },
  computed: {
    value: {
      get () {
        return this.modelValue
      },
      async set (value) {
        await this.$emit('update:modelValue', value)
        await this.$emit('checkAmount')
      }
    },
    isActive () {
      if (this.value || this.isFocus) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    notFocus () {
      this.isFocus = false
    },
    focus () {
      this.isFocus = true
      this.$emit('customFocus')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .form-control {
    background-color: var(--green-color-dark);
    color: #fff;
    border-radius: 2rem;
    border: 2px solid var(--green-color-dark);
    font-size: 1rem;
    height: calc(3.2rem + 4px);
    padding: 0 0.5rem 0 3.15rem;
    font-weight: 500;
    text-align: left;
    width: 110px;
  }
  .form-control:focus {
    background-color: var(--green-color-dark);
    color: var(--gold-color);
    box-shadow: none;
    border: 2px solid var(--green-color-light);
  }
  .form-control.isActive {
    background-color: var(--gold-color);
    color: var(--green-color-dark);
    box-shadow: none;
    border: 2px solid var(--gold-color);
  }
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--green-color-light) !important;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--green-color-light) !important;
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    color: var(--green-color-light) !important;
  }
</style>
