<template>
  <div class="position-absolute top-50 start-0 translate-middle-y row mx-0 align-items-center" :class="{isActive: isActive}">
    <div class="col-auto pe-1 gold-text bold">
      {{ (currencySymbol ? currencySymbol : defaultCurrency) }}
    </div>
    <div class="col-auto px-1 py-2 gold-text border-end border-gold">
    </div>
  </div>
</template>

<script>
export default {
  name: 'CurrencyFieldSymbol',
  props: ['currencySymbol', 'isActive'],
  data () {
    return {
      defaultCurrency: process.env.VUE_APP_CURRENCY_SYMBOL
    }
  }
}
</script>
<style scoped>
.isActive {
  color: var(--green-color-dark);
}
.isActive .gold-text {
  color: var(--green-color-dark);
}
.border-gold {
  border-color: var(--gold-color) !important;
}
.isActive .border-gold {
  border-color: var(--green-color-dark) !important;
}
</style>
